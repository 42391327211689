import React, { FC, useEffect, useState } from "react";
import { List, Header, Segment, Loader, Dimmer } from "semantic-ui-react";
import { fetchAllRequestsForFilter } from "../../services/Requests";
import moment from "moment";

interface Props {
    startDate: Date;
    endDate: Date;
    showOnlyTopFive?: boolean;
    segmentHeader?: string;
    height?: string;
}

interface Requester {
    staffName: string;
    requestsCount: number;
}

const TopStaffClosingRequests: FC<Props> = (props) => {
    const [requesters, setRequesters] = useState<Requester[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const formattedStartDate = moment(props.startDate).format("YYYY-MM-DDTHH:mm:ss");
            const formattedEndDate = moment(props.endDate).format("YYYY-MM-DDTHH:mm:ss");
            const requestsFilter = {
                Status: ["Closed"],
                StartDateTime: formattedStartDate,
                EndDateTime: formattedEndDate,
            };

            const allRequests = await fetchAllRequestsForFilter(requestsFilter);
            const filteredRequests = allRequests.filter((request) => request.ClosedBy); // if a request is closed by CRON job which runs every hour it does not added CloseBy and CloseByName. You don't need to show those because the main focus on seeing the staff who are closing the requests.
            const requesterMap = filteredRequests.reduce((acc, request) => {
                const closedBy = request.ClosedBy;
                const closeByName = request.ClosedByName;
                if (!acc[closedBy]) {
                    acc[closedBy] = { name: closeByName, count: 0 };
                }
                acc[closedBy].count += 1;

                return acc;
            }, {});

            const requestersArray = Object.values(requesterMap).map((entry: any) => ({
                // @ts-ignore
                staffName: entry.name,
                requestsCount: entry.count,
            }));

            requestersArray.sort((a, b) => b.requestsCount - a.requestsCount);

            setRequesters(requestersArray);
        } catch (error) {
            console.error("Error fetching requests", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    const defaultStyle = { width: "300px" }; 
    const segmentStyle = props.height ? { ...defaultStyle, height: props.height } : defaultStyle;
    return (
        <Segment style={segmentStyle}>
            <Dimmer active={isLoading} inverted>
                <Loader>Loading...</Loader>
            </Dimmer>
            <Header as="h4" style={{ marginBottom: "10px", marginTop: "0px" }}>
                {props.segmentHeader ? props.segmentHeader : "Staff"}
            </Header>
            <List divided verticalAlign="middle">
                {requesters.map((requester, index) => (
                    <List.Item key={index}>
                        <List.Content floated="right">
                            <span>{requester.requestsCount}</span>
                        </List.Content>
                        <List.Content>
                            <span
                                style={{
                                    display: "inline-block",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {requester.staffName}
                            </span>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default TopStaffClosingRequests;
