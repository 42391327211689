import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm, Checkbox } from 'semantic-ui-react'
import * as Yup from 'yup'
import { isFacilityAdmin, isPowerUser, isSysAdmin } from "../../../../services/Permissions";
import {
    GenericTextField,
    GenericImageField,
    GenericDataSourceDropdown,
    GenericToggleList,
} from '../../../../components/GenericFormFields'
import PasswordResetInputFields from '../../../../components/PasswordResetInputFields';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './style.less'
import { Department, User, UserRole, Facility, AuthState } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../../actions/auth';
import { toast } from 'react-toastify';
import { Group } from '../../../../types/Group';

export interface FormValues {
    Email: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    Cell: string;
    Icon: string;
    Username: string;
    Facility?: string;
    Roles: UserRole[];
    Departments: Department[];
    TemporaryPassword?: string;
    ConfirmPassword?: string;
    PasswordReset?: string;
    ConfirmPasswordReset?: string;
    VerificationCode?: number | string;
    AnnouncementAlerts?: boolean;
    EmailAnnouncementAlert?: string;
    CommunityLiaison?: boolean;
    Groups?: Group[];
}

const imagePlaceholder = `${process.env.PUBLIC_URL}/avatar_placeholder.svg`

interface Props {
    user: User | null;
    departments: Department[];
    facilities: Facility[];
    roles: UserRole[];
    error: string | null;
    title: string;
    onSave: (data: FormValues) => Promise<any>;
    captureNewPasswordModalOpen?: () => any;
    cancel: () => any;
    onDelete?: (id: string) => Promise<any>;
    startPaswordReset?: (event) => any;
    isSaving?: boolean;
    newPasswordInputFields?: boolean;
    isSysAdmin?: boolean;
    isFederatedUserForm?: boolean;
    isEmailSending?: boolean;
    resendTempPassword?: () => Promise<any>;
    reactivateUser?: (id: string) => Promise<void>;
    groups?: Group[];
}

const UsersForm: React.SFC<Props> = (props: Props) => {
    const ValidationSchema = Yup.object().shape({
        Email: Yup.string()
            .email()
            .required(),
        FirstName: Yup.string().required('First Name is required'),
        LastName: Yup.string().required('Last Name is required'),
        Phone: Yup.string(),
        Cell: Yup.string(),
        Icon: Yup.string(),
        Username: (props.isFederatedUserForm || (props.user && props.user.federatedAuthUser) ? Yup.string().required('Username is required') : Yup.string().required().test('lowercase', 'Username cannot contain uppercase letters', (value) => !/[A-Z]/.test(value))), // for federated auth users we allow uppercase letters in the username because we dont have control over the username and its autogenerated from azure
        Facility: Yup.string(),
        Roles: Yup.array()
            .of(Yup.object())
            .required(),
        Departments: Yup.array().of(Yup.object()),
        TemporaryPassword: Yup.string().min(6),
        ConfirmPassword: Yup.string().oneOf([Yup.ref('TemporaryPassword')], 'Passwords must match'),
        PasswordReset: Yup.string().min(8),
        ConfirmPasswordReset: Yup.string().oneOf([Yup.ref('PasswordReset')], 'Passwords must match'),
        VerificationCode: Yup.number().nullable(true),
        AnnouncementAlerts: Yup.boolean().notRequired(),
        EmailAnnouncementAlert: Yup.string().notRequired(),
        CommunityLiaison: Yup.boolean().notRequired(),
        IsActive: Yup.boolean().notRequired()
    })
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Email: (props.user && props.user.Email) || '',
        FirstName: (props.user && props.user.FirstName) || '',
        LastName: (props.user && props.user.LastName) || '',
        Phone: (props.user && props.user.Phone) || '',
        Cell: (props.user && props.user.Cell) || '',
        Icon: (props.user && props.user.Icon) || '',
        Username: (props.user && props.user.Username) || '',
        Facility: (props.user && props.user.Facility) || undefined,
        Roles: (props.user && props.user.Roles) || [],
        Departments: (props.user && props.user.Departments) || [],
        TemporaryPassword: '',
        ConfirmPassword: '',
        PasswordReset: '',
        ConfirmPasswordReset: '',
        VerificationCode: '',
        AnnouncementAlerts: (props.user && props.user.AnnouncementAlerts) || false,
        EmailAnnouncementAlert: (props.user && props.user.EmailAnnouncementAlert) || '',
        CommunityLiaison: (props.user && props.user.CommunityLiaison) || false,
        IsActive : (props.user && props.user.IsActive) || 0, 
        Groups: (props.user && props.user.Groups) || []
    })
    const profile = useSelector((state: { authReducer: AuthState }) => state.authReducer.profile);
    const federatedAuthUser = useSelector((state: { authReducer: AuthState }) => state.authReducer.federatedAuthUser);
    const dispatch = useDispatch();

    const canDelete = () => {
        return props.onDelete && isEdit()
    }

    const canReactivate = () => {
        return props.reactivateUser && isEdit()
    }

    const isEdit = () => (props.user && props.user._id ? true : false)

    const isActiveUser = props && props.user && props.user.IsActive;


    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (canDelete()) {
            // @ts-ignore
           props.onDelete(props.user._id) 
        }
    }

    const reactivate = () => {
        if (canReactivate()) {
            // @ts-ignore
            props.reactivateUser(props.user._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }

    const confirm = () => {
        close()
       isActiveUser ? doDelete() : reactivate()
    }

    const isStaffUser = user => {
        if (user) {
            return user.Roles.some(role => {
                return role.Name === 'Staff';
            });
        }
        return false;
    };
    const resetPasswordClickHandler = (e) => {
        e.preventDefault();
        if (props.user && props.user.federatedAuthUser) {
            toast.warn("Password changes are not supported for users with corporate login", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }
        props.startPaswordReset && props.startPaswordReset(e);
    }
    const togglePasswordResetViews = () => {
        if (props.newPasswordInputFields) {
            return <PasswordResetInputFields
                GenericTextField={GenericTextField}
            />
        }
        if (isStaffUser(props.user) && !props.error) {
            return (
                <Button onClick={resetPasswordClickHandler} className="password-reset-button">
                    Reset Password
                </Button>
            );
        }
    }

    const setPasswordView = () => {
        if (props && props.isSysAdmin && !props.error) {
            return (
                <div>
                    <Button onClick={e => {
                        e.preventDefault();
                        if (props.user && props.user.federatedAuthUser) {
                            toast.warn("Password changes are not supported for users with corporate login", {
                                position: 'bottom-center',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            return;
                        }
                        props.captureNewPasswordModalOpen && props.captureNewPasswordModalOpen()
                    }} className="password-reset-button">
                        Set New Password
                    </Button>
                </div>

            );
        }
    }

    const handleBackToLogin = () => {
        dispatch(logOut(null))// passed null to avoid /users/updateLoginStatus call being made internally as its not relevant for new federated user 
    }
    return (
        <div className="UsersForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header={`Confirm  ${isActiveUser ? "delete": "reactivate"} `}
                content={`Are you sure you want to ${isActiveUser ? "delete": "reactivate"} this item?`}
            />
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                    await props.onSave(values);
                    actions.setSubmitting(false);
                }}
                validationSchema={ValidationSchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        {!props.isFederatedUserForm ? <FormikField name="Icon" component={GenericImageField} placeholder={imagePlaceholder} /> : <></>}
                        <FormikField required name="FirstName" component={GenericTextField} placeholder="First Name" />
                        <FormikField required name="LastName" component={GenericTextField} placeholder="Last Name" />
                        <Form.Field>
                            <label>Cell</label>
                            <PhoneInput
                                style={{ padding: '5px' }}
                                defaultCountry="US"
                                value={values.Cell}
                                placeholder="Enter Cell number"
                                onChange={(phone) => {
                                    handleChange({
                                        target: {
                                            name: 'Cell',
                                            value: phone,
                                        },
                                    });
                                }}
                            />
                        </Form.Field>
                        {
                            !props.isFederatedUserForm ? <Form.Field>
                                <label>Phone</label>
                                <PhoneInput
                                    style={{ padding: '5px' }}
                                    defaultCountry="US"
                                    value={values.Phone}
                                    placeholder="Enter phone number"
                                    onChange={(phone) => {
                                        handleChange({
                                            target: {
                                                name: 'Phone',
                                                value: phone,
                                            },
                                        });
                                    }}
                                />
                            </Form.Field> : <></>
                        }
                        <FormikField
                            required
                            name="Username"
                            component={GenericTextField}
                            placeholder="Username"
                            disabled={isEdit() || props.isFederatedUserForm}
                        />

                        {isEdit() && togglePasswordResetViews()}
                        {isEdit() && setPasswordView()}
                        <FormikField
                            required
                            name="Email"
                            component={GenericTextField}
                            placeholder="Email"
                            type="email"
                            disabled={props.isFederatedUserForm}
                        />
                        {(
                            props.groups && props.groups.length &&
                            <FormikField
                                data={props.groups}
                                name="Groups"
                                component={GenericDataSourceDropdown}
                                placeholder="Groups"
                                labelFields={['Name']}
                                multiple
                            />
                        ) || null}
                        {(!isEdit() && !props.isFederatedUserForm && (
                            <FormikField
                                required
                                name="TemporaryPassword"
                                component={GenericTextField}
                                type="password"
                                placeholder="Temporary Password"
                                autoComplete="new-password"
                            />
                        )) ||
                            null}
                        {(!isEdit()  && !props.isFederatedUserForm && (
                            <FormikField
                                required
                                name="ConfirmPassword"
                                component={GenericTextField}
                                type="password"
                                placeholder="Confirm Password"
                                autoComplete="new-password-confirm"
                            />
                        )) ||
                            null}
                        {(props.facilities.length && (
                            <FormikField
                                data={props.facilities}
                                required
                                name="Facility"
                                component={GenericDataSourceDropdown}
                                placeholder="Facility"
                                labelFields={['Name']}
                                valueField="_id"
                                disabled={federatedAuthUser}
                            />
                        )) ||
                            null}
                        <FormikField
                            data={props.roles}
                            required
                            name="Roles"
                            component={GenericDataSourceDropdown}
                            placeholder="Roles"
                            labelFields={['Name']}
                            multiple
                            disabled={props.isFederatedUserForm}
                        />
                        {(isPowerUser(profile) || isSysAdmin(profile) || isFacilityAdmin(profile)) &&
                            values.Roles.filter((selectedRoles) => selectedRoles.Name === 'Staff' || selectedRoles.Name === 'Power User').length ? (
                            <>
                                <Form.Field>
                                    <FormikField
                                        name="EmailAnnouncementAlert"
                                        component={GenericTextField}
                                        placeholder="Email for announcement alerts"
                                        type="email"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        label={'Announcement alerts'}
                                        checked={values.AnnouncementAlerts}
                                        onChange={async (e, data) => {
                                            e.preventDefault();
                                            handleChange({
                                                target: {
                                                    name: 'AnnouncementAlerts',
                                                    value: !values.AnnouncementAlerts,
                                                },
                                            });
                                        }}
                                    />
                                </Form.Field>
                            </>
                        ) : <></>
                        }
                        {
                            (isSysAdmin(profile) || isFacilityAdmin(profile)) && values.Roles.filter((selectedRoles) => selectedRoles.Name === 'Power User').length ?
                                <>
                                    <Form.Field>
                                        <Checkbox
                                            toggle
                                            label={'Community Liaison'}
                                            checked={values.CommunityLiaison}
                                            onChange={async (e, data) => {
                                                e.preventDefault();
                                                handleChange({
                                                    target: {
                                                        name: 'CommunityLiaison',
                                                        value: !values.CommunityLiaison,
                                                    },
                                                });
                                            }}
                                        />
                                    </Form.Field>
                                </> :
                                <></>
                        }
                        {(!props.facilities.length && !props.isFederatedUserForm && props.departments && props.departments.length > 0 && (
                            <FormikField
                                data={props.departments.sort((a, b) => a.Name.localeCompare(b.Name))}
                                required
                                name="Departments"
                                component={GenericToggleList}
                                placeholder="Departments"
                                labelFields={['Name']}
                            />
                        )) ||
                            null}
                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving User</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        {!props.isFederatedUserForm ? <Button basic type="button" onClick={handleCancel}>Cancel</Button> : <></>}
                        {props.isFederatedUserForm ? <Button basic type="button" onClick={handleBackToLogin}>Back to login</Button> : <></>}
                        {(canDelete() || canReactivate()) && (
                            <Button basic color={isActiveUser ? "red" : "green"} loading={props.isSaving} onClick={handleDelete}>
                                {isActiveUser ? 'Remove user' : 'Reactivate user'}
                            </Button>
                        )}
                        {
                            props.user && props.user.ConfirmPassword && <Button type="button" basic color='blue' loading={props.isEmailSending} onClick={async () => {
                                if (props.resendTempPassword) {
                                    await props.resendTempPassword();
                                }
                            }}>
                               Resend temporary password
                            </Button>
                        }
                    </Form>
                )}
            />
        </div>
    );
}

export default UsersForm
