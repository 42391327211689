import React, { FC, useEffect, useState } from "react";
import { List, Header, Segment } from "semantic-ui-react";
import { listAllMessagesForFilter } from "../../services/Messages";
import moment from "moment";
import { sendToast } from "../../util";
interface Props {
    startDate: Date;
    endDate: Date;
    showOnlyTopFive?: boolean;
    height?: string;
}

interface MessageRecord {
    residentName: string;
    totalMessages: number;
}

const TopMessages: FC<Props> = (props) => {
    const [messageRecords, setMessageRecords] = useState<MessageRecord[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Sort and optionally limit to top 5
    const sortedMessageRecords = [...messageRecords]
        .sort((a, b) => b.totalMessages - a.totalMessages)
        .slice(0, props.showOnlyTopFive ? 5 : messageRecords.length);

    const fetchMessageData = async () => {
        try {
            setIsLoading(true);
            const messagesFilter = {
                sentAt: {
                    $gte: moment(props.startDate).valueOf(),
                    $lte: moment(props.endDate).valueOf(),
                }
            };

            const messagesResponse = await listAllMessagesForFilter(messagesFilter);

            // Filter messages sent or received by residents
            const residentMessages = messagesResponse.filter(
                (message) => message.residentId && message.residentName
            );

            // Group and count messages for each resident
            const messageMap = residentMessages.reduce((acc, message) => {
                const residentName = message.residentName;

                if (!acc[residentName]) {
                    acc[residentName] = 0;
                }
                acc[residentName] += 1;

                return acc;
            }, {} as Record<string, number>);

            // Convert the map to an array
            const messageRecordsArray = Object.entries(messageMap).map(
                ([residentName, totalMessages]) => ({
                    residentName,
                    totalMessages,
                })
            );

            // Sort the array by total messages in descending order
            messageRecordsArray.sort((a, b) => b.totalMessages - a.totalMessages);

            setMessageRecords(messageRecordsArray);
        } catch (error) {
            console.error("Error fetching message data", error);
            sendToast("error", error instanceof Error ? error.message : "Error fetching message data");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMessageData();
    }, []);


    const defaultStyle = { width: "300px" };
    const segmentStyle = props.height ? { ...defaultStyle, height: props.height } : defaultStyle;
    return (
        <Segment style={segmentStyle} loading={isLoading}>
            <Header as="h4" style={{ marginBottom: "10px" }}>
                Messages
            </Header>
            <List divided verticalAlign="middle">
                {sortedMessageRecords.map((record, index) => (
                    <List.Item key={index}>
                        <List.Content floated="right">
                            <span>{record.totalMessages}</span>
                        </List.Content>
                        <List.Content>
                            <span
                                style={{
                                    display: "inline-block",
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                }}
                            >
                                {record.residentName}
                            </span>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default TopMessages;
