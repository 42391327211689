import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { fetchAllActivityAndServiceInstance, sendAlertsToResidents } from '../../services/ServiceInstances';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { Promise as Bluebird } from 'bluebird';
import { listActivityAttendees } from '../../services/DailyActivities';
import { Button, Checkbox, Dimmer, Header, Icon, Input, Loader, Modal, Segment, Table, TextArea, Pagination } from 'semantic-ui-react';
import { sendToast } from '../../util';
import { ServicesType } from '../../types/ServicesTypes';
import { fetchAllPaginatedResidents, listResidentInterests } from '../../services/Registrants';
import { fetchOneServiceType, getServiceWellness } from '../../services/service';
import { DateRange } from '../CustomDatePicker';
import HighlightTextarea from './HighlightTextarea';
import { Link } from 'react-router-dom';
// todo currently I am going to test this for service instance - I also have to test it for normal activities by disabling service instance

interface formattedActivityData {
    activityName: string;
    activityTime: string;
    confirmedCount: number;
    matchedCount: number;
    metaData: {
        activity: ServicesType;
        confirmedAndMatchedResidentsData: {
            confirmedResidents: {
                count: number;
                residentIds: string[];
            };
            matchedResidents: {
                count: number;
                residentIds: string[];
            };
            attendedResidents: {
                count: number;
                residentIds: string[];
            }
        };
    };
    activityId: string;
}

interface ActivityAnalysisProps {
    dateRange: DateRange;
    defaultMessage: string;
};

const ActivityAnalysis: FC<ActivityAnalysisProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableDataForActivities, setTableDataForActivities] = useState<formattedActivityData[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<formattedActivityData | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const [registrantsDict, setRegistrantsDict] = useState<Record<string, { registrantName: string; }>>({});
    const [registrantInterestsDict, setRegistrantInterestsDict] = useState<Record<string, { interests: string[]; }>>({});
    const [loadingActivityId, setLoadingActivityId] = useState<string | null>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [activitiesPerPage] = useState<number>(10);

    const indexOfLastActivity = currentPage * activitiesPerPage;
    const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
    const currentActivities = tableDataForActivities.slice(indexOfFirstActivity, indexOfLastActivity);
    const totalPages = Math.ceil(tableDataForActivities.length / activitiesPerPage);
    const isPastActivity = moment(props.dateRange.start).isBefore(moment(), 'day') && props.dateRange.end; // Check if the selected date is in the past


    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const facility = profile && profile.Facility || "";
    const facilityTimeZone = profile && profile.FacilityTimeZone;


    useEffect(() => {
        (async () => {
            await fetchAllResidents();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (props.dateRange.start && props.dateRange.end) {
                console.log("prps.dateRange.start", props.dateRange.start);
                console.log("prps.dateRange.end", props.dateRange.end);
                await getAllActivitiesTableDataForToday();
            }
        })();

    }, [props.dateRange.start, props.dateRange.end]);


    const fetchAllResidents = async () => {
        try {
            if (profile && profile.Facility) {
                const allResidents = await fetchAllPaginatedResidents({ Facility: profile.Facility });
                //add to the registrantsDict
                if (allResidents && allResidents.length > 0) {
                    allResidents.forEach((resident) => {
                        setRegistrantsDict((prev) => {
                            return {
                                ...prev,
                                [resident._id]: {
                                    registrantName: `${resident.FirstName} ${resident.LastName}`
                                }
                            };
                        });
                    });
                }
            }
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Something went wrong!");
        }
    };

    const getAllActivitiesTableDataForToday = async () => {
        try {
            type RegistrantInterestsDict = Record<string, { interests: string[]; }>;
            let updatedRegistrantInterestsDict: RegistrantInterestsDict = { ...registrantInterestsDict };
            setIsLoading(true);

            const startOfToday = momentTz(props.dateRange.start, facilityTimeZone)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
            const endOfToday = momentTz(props.dateRange.end, facilityTimeZone)
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
            console.log("startOfToday", startOfToday);
            console.log("endOfToday", endOfToday);
            const filter = {
                startDateTimeString: startOfToday,
                endDateTimeString: endOfToday,
                Facility: facility
            };

            const activities = await fetchAllActivityAndServiceInstance(filter);
            if (activities) {

                const allResidentInterests = await listResidentInterests(undefined, true);
                //add to the registrantsDict
                if (allResidentInterests && allResidentInterests.Result.length > 0) {

                    allResidentInterests.Result.forEach((residentInterest) => {
                        updatedRegistrantInterestsDict[residentInterest.residentId] = {
                            interests: residentInterest,
                        };
                    });

                    // Now, update the state with the modified dictionary
                    setRegistrantInterestsDict(updatedRegistrantInterestsDict);
                }

                // Preload wellness data
                const wellnessDataMap: Record<string, any> = {};
                await Bluebird.map(activities, async (activity) => {
                    if (activity.wellnessId) {
                        wellnessDataMap[activity._id] = await getServiceWellness(activity.wellnessId);
                    } else if (activity.originalServiceId) {
                        const ServiceData = await fetchOneServiceType(activity.originalServiceId, activity.Facility);
                        wellnessDataMap[activity._id] = ServiceData.Wellness;
                    }
                }, { concurrency: 10 });


                // Continue with processing activities
                const tableDataForActivities: formattedActivityData[] = [];
                await Bluebird.map(activities, async (activity) => {
                    const activityId = activity._id;
                    const attendanceData = await listActivityAttendees(activityId, true);

                    const confirmedAndMatchedResidentsData = await handleAttendanceDataForActivity(
                        activity,
                        attendanceData.Result,
                        updatedRegistrantInterestsDict,
                        wellnessDataMap[activity._id] // Pass preloaded wellness data
                    );

                    const formattedActivityData: formattedActivityData = {
                        activityName: activity.name,
                        activityTime: activity.startDate,
                        confirmedCount: confirmedAndMatchedResidentsData.confirmedResidents.count,
                        matchedCount: confirmedAndMatchedResidentsData.matchedResidents.count,
                        metaData: {
                            activity,
                            confirmedAndMatchedResidentsData
                        },
                        activityId: activity._id
                    };

                    tableDataForActivities.push(formattedActivityData);
                }, { concurrency: 10 });

                tableDataForActivities.sort((a, b) =>
                    moment(a.activityTime).isBefore(moment(b.activityTime)) ? -1 : 1
                );

                setTableDataForActivities(tableDataForActivities);
            }
        } catch (error) {
            sendToast("warn", error instanceof Error ? error.message : "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    const handleAttendanceDataForActivity = async (activity, attendanceData, updatedRegistrantInterestsDict, wellnessData) => {
        // get the number of confirm residents
        // get the number of matched residents
        const confirmedResidents: {
            count: number;
            residentIds: string[];
        } = {
            count: 0,
            residentIds: []
        };

        const matchedResidents: {
            count: number;
            residentIds: any[];
        } = {
            count: 0,
            residentIds: []
        };

        const attendedResidents: {
            count: number;
            residentIds: any[];
        } = {
            count: 0,
            residentIds: []
        }


        //so if attendance data is empty then handleTheMatchingResident
        if (attendanceData.length === 0) {
            // Assuming `handleMatchingResident` returns an array of resident IDs who are matched
            const matchedResidentsList = await handleMatchingResident(activity, updatedRegistrantInterestsDict, wellnessData);
            if (matchedResidentsList.length > 0) {
                const updatedCount = matchedResidents.count + matchedResidentsList.length;
                const updatedResidents = [...matchedResidents.residentIds, ...matchedResidentsList]; // Add matched residents to existing list
                matchedResidents.count = updatedCount;
                matchedResidents.residentIds = updatedResidents;
            }
        } else {
            attendanceData.forEach((attendance) => {
                if (attendance.status === "intend_attend" || attendance.status === "attended" || attendance.status === "declined" || attendance.status ==="waiting") {
                    if(attendance.status === "attended"){
                        const updatedCount = attendedResidents.count + 1;
                        const updatedResidentIds = [...attendedResidents.residentIds, attendance.registrantId];
                        attendedResidents.count = updatedCount;
                        attendedResidents.residentIds = updatedResidentIds;
                    } else {
                        const updatedCount = confirmedResidents.count + 1;
                        const updatedResidentIds = [...confirmedResidents.residentIds, attendance.registrantId];
                        confirmedResidents.count = updatedCount;
                        confirmedResidents.residentIds = updatedResidentIds;
    
                        // Remove the confirmed resident from updatedRegistrantInterestsDict
                        delete updatedRegistrantInterestsDict[attendance.registrantId];
                    }
                }
            });

            // Handle matching residents for the remaining entries in updatedRegistrantInterestsDict
            const remainingMatchedResidentsList: any = await handleMatchingResident(activity, updatedRegistrantInterestsDict, wellnessData);

            if (remainingMatchedResidentsList.length > 0) {
                const updatedCount = matchedResidents.count + remainingMatchedResidentsList.length;
                const updatedResidents = [...matchedResidents.residentIds, ...remainingMatchedResidentsList]; // Add matched residents to existing list
                matchedResidents.count = updatedCount;
                matchedResidents.residentIds = updatedResidents;

                // Remove the newly matched residents from updatedRegistrantInterestsDict
                remainingMatchedResidentsList.forEach((residentId) => {
                    if (updatedRegistrantInterestsDict[residentId]) {
                        delete updatedRegistrantInterestsDict[residentId];
                    }
                });
            }
        }


        return {
            confirmedResidents,
            matchedResidents,
            attendedResidents
        };
    };

    const handleMatchingResident = async (activity, updatedRegistrantInterestsDict, wellnessDataObj) => {
        try {
            let wellnessData = wellnessDataObj;
            if (!wellnessData) {
                return [];
            }
            // Prepare to track matched residents
            const matchedResidentIds: string[] = [];
            // Helper function to check if any value is true inside a category or subcategory
            const hasTrueInterest = (interestData: any): boolean => {
                for (let value of Object.values(interestData)) {
                    if (value === true) {
                        return true;
                    }
                }
                return false;
            };
            // Iterate over wellness data categories and check for matches with resident interests
            for (const category in wellnessData) {                
                for (const subCategory in wellnessData[category]) {
                    for (const interest in wellnessData[category][subCategory]) {
                        if (wellnessData[category][subCategory][interest]) {
                            // Check if the category or subcategory has any true interests before proceeding
                            if (!hasTrueInterest(wellnessData[category][subCategory][interest])) {
                                continue;
                            }
                            // Check if any resident's interest matches the wellness data
                            const matchingResidents = Object.keys(updatedRegistrantInterestsDict).filter(residentId => {
                                const residentData = updatedRegistrantInterestsDict[residentId];
                                if (!residentData || !residentData.interests) {
                                    return false;
                                }
                                const residentInterests = residentData.interests;
                                // Check if this specific interest exists in the resident's interests
                                if (residentInterests[subCategory]) {
                                    const interestMatch = residentInterests[subCategory][interest] || false;
                                    const wellnessDataInterest = wellnessData[category][subCategory][interest];
                                    if (interestMatch && wellnessDataInterest) {
                                        for (let key in wellnessDataInterest) {
                                            if (wellnessDataInterest[key] && interestMatch[key]) {
                                                if (wellnessDataInterest[key] && interestMatch[key]) {
                                                    return true;
                                                }
                                            }
                                        }
                                    }
                                }
                                return false;
                            });
                            matchedResidentIds.push(...matchingResidents);
                        }
                    }
                }
            }
            // Remove duplicates from matched residents list
            const uniqueMatchedResidents = Array.from(new Set(matchedResidentIds));
            return uniqueMatchedResidents;
        } catch (error) {
            console.error("Error matching resident interests:", error);
        }
        return [];
    };

    const handleNameClick = (activity: formattedActivityData) => {
        setSelectedActivity(activity);
        setIsModalOpen(true);
    };

    const handleAnnouncementClick = async (activity: formattedActivityData) => {
        try {
            // Set the loading state to the clicked activity ID
            setLoadingActivityId(activity.activityId);
            // check if activity startDate is past if yes then send a toast saying cannot send announcement for past activities
            const activityStartDate = moment(activity.activityTime);
            const currentDateTime = moment();
            if (activityStartDate.isBefore(currentDateTime)) {
                sendToast("warn", "Cannot send announcement for past activities!");
                setLoadingActivityId(null);
                return;
            }
            const matchedResidents = (activity && activity.metaData && activity.metaData.confirmedAndMatchedResidentsData &&
                activity.metaData.confirmedAndMatchedResidentsData.matchedResidents &&
                activity.metaData.confirmedAndMatchedResidentsData.matchedResidents.residentIds)
                || [];
            if (matchedResidents.length > 0) {
                // Send the alerts to the matched residents
                const sendAlerts = await sendAlertsToResidents({ activityId: activity.activityId, residentIds: matchedResidents, message: props.defaultMessage });
                if (sendAlerts && sendAlerts.message) {
                    sendToast("success", sendAlerts.message);
                }
            } else {
                sendToast("warn", "No matched residents found for this activity!");
                setLoadingActivityId(null);
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "Something went wrong!";
            sendToast("error", errorMessage);
        } finally {
            setLoadingActivityId(null);
        }
    };

    const handlePageChange = (e: React.MouseEvent, { activePage }: any) => {
        setCurrentPage(activePage);
    };

    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Dimmer active inverted>
                        <Loader active content="Please wait, data is being populated..." />
                    </Dimmer>
                </div>
            ) : (
                <div>
                    <div style={{ minHeight: '300px', position: 'relative' }}>
                        <Table celled>
                            <Table.Header style={{ backgroundColor: "#FBFBFB" }}>
                                <Table.Row>
                                    <Table.HeaderCell>Activity</Table.HeaderCell>
                                    <Table.HeaderCell>Time</Table.HeaderCell>
                                    <Table.HeaderCell>Confirmed</Table.HeaderCell>
                                    {isPastActivity && <Table.HeaderCell>Attended</Table.HeaderCell>}
                                    <Table.HeaderCell>Matched</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {currentActivities.length > 0 ? (
                                    currentActivities.map((activity, index) => (
                                        <Table.Row
                                    key={activity.activityId}
                                    style={{
                                        // Alternate row background color should be light blue
                                        backgroundColor: index % 2 === 0 ? "rgba(209, 236, 255, 0.5)" : "transparent",
                                    }}
                                >
                                            <Table.Cell>
                                                <span
                                                    style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleNameClick(activity);
                                                    }}
                                                >
                                                    {activity.activityName}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>{moment(activity.activityTime).format("hh:mm A")}</Table.Cell>
                                            <Table.Cell>{activity.confirmedCount}</Table.Cell>
                                            {isPastActivity && (
                                                <Table.Cell>
                                                    {activity.metaData.confirmedAndMatchedResidentsData.attendedResidents.count}
                                                </Table.Cell>
                                            )}
                                            <Table.Cell>{activity.matchedCount}</Table.Cell>
                                            <Table.Cell>
                                                {loadingActivityId === activity.activityId ? (
                                                    <Loader active inline size="small" />
                                                ) : (
                                                    <Icon
                                                        name="announcement"
                                                        color="black"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleAnnouncementClick(activity);
                                                        }}
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                ) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={isPastActivity ? 5 : 4} style={{ textAlign: 'center', color: '#666' }}>
                                            No activities to display.
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>

                    </div>

                    {/* Centered Pagination */}
                    {totalPages > 1 && (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Pagination
                                activePage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                                size="mini" // Reduced size
                            />
                        </div>
                    )}
                </div>
            )}

            {selectedActivity && (
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <Modal.Header>{selectedActivity ? selectedActivity.activityName : ''} Details</Modal.Header>
                    <Modal.Content>
                        <div
                            style={{
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '16px',
                                backgroundColor: '#fff',
                            }}
                        >
                            {/* Conditionally adjust the grid layout based on isPastActivity */}
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: isPastActivity ? '1fr 1fr 1fr' : '1fr 1fr',
                                gap: '16px',
                            }}>
                                {/* Confirmed Residents */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h4 style={{ marginBottom: '8px', fontSize: '16px', fontWeight: 'bold' }}>Confirmed</h4>
                                    {selectedActivity.metaData.confirmedAndMatchedResidentsData.confirmedResidents.residentIds.length > 0 ? (
                                        selectedActivity.metaData.confirmedAndMatchedResidentsData.confirmedResidents.residentIds.map(
                                            (id: string, index: number) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        padding: '8px',
                                                        fontSize: '14px',
                                                        lineHeight: '1.5',
                                                        borderBottom: '1px solid #ddd',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.2s ease',
                                                    }}
                                                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f8ff')}
                                                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                                                >
                                                    <Link to={`/admin/registrant/list/${id}`} target="_blank" rel="noopener noreferrer">
                                                        {registrantsDict[id].registrantName || ''}
                                                    </Link>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <p style={{ padding: '8px', fontSize: '14px', color: '#666' }}>
                                            No confirmed residents for this activity.
                                        </p>
                                    )}
                                </div>

                                {/* Attended Residents - Only if past activity */}
                                {isPastActivity && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h4 style={{ marginBottom: '8px', fontSize: '16px', fontWeight: 'bold' }}>Attended</h4>
                                        {selectedActivity.metaData.confirmedAndMatchedResidentsData.attendedResidents.residentIds.length > 0 ? (
                                            selectedActivity.metaData.confirmedAndMatchedResidentsData.attendedResidents.residentIds.map(
                                                (id: string, index: number) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            padding: '8px',
                                                            fontSize: '14px',
                                                            lineHeight: '1.5',
                                                            borderBottom: '1px solid #ddd',
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.2s ease',
                                                        }}
                                                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f8ff')}
                                                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                                                    >
                                                        <Link to={`/admin/registrant/list/${id}`} target="_blank" rel="noopener noreferrer">
                                                            {registrantsDict[id].registrantName || ''}
                                                        </Link>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <p style={{ padding: '8px', fontSize: '14px', color: '#666' }}>
                                                No attended residents for this activity.
                                            </p>
                                        )}
                                    </div>
                                )}

                                {/* Matched Residents */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h4 style={{ marginBottom: '8px', fontSize: '16px', fontWeight: 'bold' }}>Match Detail</h4>
                                    {selectedActivity.metaData.confirmedAndMatchedResidentsData.matchedResidents.residentIds.length > 0 ? (
                                        selectedActivity.metaData.confirmedAndMatchedResidentsData.matchedResidents.residentIds.map(
                                            (id: string, index: number) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        padding: '8px',
                                                        fontSize: '14px',
                                                        lineHeight: '1.5',
                                                        borderBottom: '1px solid #ddd',
                                                        cursor: 'pointer',
                                                        transition: 'background-color 0.2s ease',
                                                    }}
                                                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f8ff')}
                                                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                                                >
                                                    <Link to={`/admin/registrant/list/${id}`} target="_blank" rel="noopener noreferrer">
                                                        {registrantsDict[id].registrantName || ''}
                                                    </Link>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <p style={{ padding: '8px', fontSize: '14px', color: '#666' }}>
                                            No matched residents for this activity.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Icon name="close" onClick={() => setIsModalOpen(false)} style={{ cursor: 'pointer', textAlign: 'center' }} />
                    </Modal.Actions>
                </Modal>
            )}

        </div>
    );
      
};

export default ActivityAnalysis;